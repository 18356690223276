import React from 'react';

const Portfolio = () => {
  return (
    <div>
      <h1>Portfolio Tome ohpe thisi workd</h1>
    </div>
  );
};

export default Portfolio;
